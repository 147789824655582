<template>
  <div class="users-list-container">
    <h3 v-if="removeOneByOne" class="header">
      {{ membersList.length }} members in {{ chatroomName }}
    </h3>
    <div
      v-if="navigateAddUser"
      class="add-companies"
      @click="$emit('navigate-add-user')"
      style="color: #473068; font-weight: bold"
    >
      Add Companies
    </div>
    <table class="selected-table" v-if="selectedList.length">
      <tr v-for="member in selectedList" :key="member.id">
        <td class="selected-table-row">
          <tr>
            <td class="logo">
              <div class="square-avatar">
                <v-img v-if="member.avatar" :src="member.avatar" />
                <v-icon v-else size="40" color="#473068">mdi-domain</v-icon>
              </div>
            </td>
            <td class="table-content">
              <div class="name">{{ member.name }}</div>
              <div class="role" v-if="member.role">{{ member.role }}</div>
            </td>
          </tr>
        </td>
        <td class="remove-user" @click="removeItem(member)">
          <popup-close :fillOpacity="0" />
        </td>
      </tr>
    </table>
    <div v-if="searchField" class="search-container">
      <div class="search-field-wrapper">
        <v-icon class="search-icon" size="20">mdi-magnify</v-icon>
        <input
          v-model="searchValue"
          class="search-field"
          type="text"
          :placeholder="placeholder"
          id="search"
          name="search"
        />
      </div>
    </div>
    <table class="members-table" v-if="listToShow.length">
      <tr v-for="member in listToShow" :key="member.id">
        <td
          class="table-row"
          :style="`${removeOneByOne ? 'cursor: initial;' : ''}`"
          @click="currentUserOrganization !== member.id && addItem(member)"
        >
          <tr>
            <td class="logo">
              <div class="square-avatar">
                <v-img v-if="member.avatar" :src="member.avatar" />
                <v-icon v-else size="40" color="#473068">mdi-domain</v-icon>
              </div>
            </td>
            <td class="table-content">
              <div class="name">{{ member.name }}</div>
              <div class="role" v-if="member.role">{{ member.role }}</div>
            </td>
          </tr>
        </td>
        <td
          v-if="removeOneByOne && member.id !== currentUserOrganization"
          class="remove-user remove-one-by-one"
          @click="currentUserOrganization !== member.id && addItem(member)"
          style="color: #473068; font-weight: bold; cursor: pointer"
        >
          Remove
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
import { number } from "@amcharts/amcharts4/core";
import PopupClose from "../assets/icons/PopupClose.vue";
import { integer } from "vee-validate/dist/rules";

export default {
  name: "UsersListView",
  components: {
    PopupClose,
  },
  props: {
    chatroomName: {
      type: String,
      default: "",
    },
    currentUserOrganization: {
      type: integer,
      default: () => ({}),
    },
    searchField: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    membersList: {
      type: Array,
      default: () => [],
    },
    selectedList: {
      type: Array,
      default: () => [],
    },
    removeOneByOne: {
      type: Boolean,
      default: false,
    },
    navigateAddUser: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    listToShow() {
      const selectedIds = new Set(this.selectedList.map((member) => member.id));
      let filteredMembers = this.membersList.filter(
        (member) => !selectedIds.has(member.id)
      );
      if (this.searchValue) {
        const regEx = new RegExp(`\\b${this.searchValue}`, "i");
        filteredMembers = filteredMembers.filter((member) =>
          regEx.test(member.name)
        );
      }

      return filteredMembers;
    },
  },
  data: () => ({
    searchValue: "",
  }),
  methods: {
    removeItem(item) {
      this.$emit("remove-item", item);
    },
    addItem(item) {
      this.$emit("add-item", item);
    },
  },
};
</script>

<style lang="scss" scoped>
.users-list-container {
  height: auto;
  overflow: auto;
  margin: auto;
  padding: 10px;
}
.header {
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  margin-bottom: 15px;
  margin-top: -10px;
}
.add-companies {
  text-align: left;
  color: $primaryDarkest;
  cursor: pointer;
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 14px;

  &:hover {
    color: $primary;
    text-decoration: underline;
  }
}
.search-container {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 10px;

  .search-field-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
  }

  .search-icon {
    position: absolute;
    left: 10px;
    color: #6c757d;
  }

  .search-field {
    flex: 1;
    height: 40px;
    border: 2px solid #c6a8ec;
    border-radius: 4px;
    padding: 5px 35px;
    font-size: 14px;

    &:focus {
      border-color: #473068;
      outline: none;
    }
  }
}
.members-table {
  width: 100%;
  border-collapse: collapse;
}
.table-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0;
  padding: 5px 0;
}
.logo {
  width: 15%;
  text-align: left;
}

.square-avatar {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  overflow: hidden;
}

.square-avatar v-img,
.square-avatar v-icon {
  width: 100%;
  height: 100%;
}

.table-content {
  width: 75%;
  text-align: left;
  padding-left: 10px;
}
.name {
  font-size: 14px;
  font-weight: bold;
  color: #000;
}
.role {
  font-size: 12px;
  font-weight: bold;
  color: #6c757d;
}
.remove-user {
  width: 20%;
  text-align: right;

  button {
    background: none;
    border: none;
    color: $danger;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
