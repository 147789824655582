var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.files.length)?_c('v-row',{staticClass:"info-message"},[_c('div',[_vm._v("No files to show")])]):_vm._e(),_c('v-row',{attrs:{"no-gutters":""}},_vm._l((_vm.files),function(file){return _c('v-col',{key:file.id,staticClass:"image-container-col",attrs:{"no-gutters":"","align":"center"}},[(_vm.isImage(file))?_c('div',{staticClass:"image-container",style:(`background-image: url(${`${_vm.serverHost}/api/inquiries/file_show/${file.upload_name}`});`),on:{"click":function($event){return _vm.handlePreview(
            `${_vm.serverHost}/api/inquiries/file_show/${file.upload_name}`
          )}}},[_c('div',{staticClass:"more-vert",on:{"click":function($event){return _vm.openActions(file.id, $event)}}},[_c('v-icon',{staticClass:"more-vert-icon"},[_vm._v("mdi-dots-vertical")])],1),(file.id === _vm.activeActionsId)?_c('div',{staticClass:"actions-container"},[_c('div',{staticClass:"actions-list",on:{"click":function($event){return _vm.download(file, $event)}}},[_vm._v(" Download ")]),(_vm.isSupportedThumbnail(file.upload_name) && _vm.isBrandOrCreator)?_c('div',{staticClass:"actions-list",on:{"click":function($event){return _vm.setDefault(file, $event)}}},[_vm._v(" Set Default ")]):_vm._e(),(
              !_vm.checkIsOneHourPast(file.created_at) &&
              (file.user_id === _vm.userId || _vm.isBrandOrCreator)
            )?_c('div',{staticClass:"actions-list",on:{"click":function($event){return _vm.deleteFile(file, $event)}}},[_vm._v(" Delete ")]):_vm._e()]):_vm._e(),_c('center',{staticClass:"non-preview-content-container"},[_c('center',{staticClass:"non-preview-file-title"},[_c('truncate-hovering',{attrs:{"text":file.title,"length":11}})],1),_c('center',{staticClass:"non-preview-text"},[_vm._v(" "+_vm._s(file.title.split(".").pop())+" ")])],1)],1):_c('div',{staticClass:"non-preview-container"},[_c('div',{staticClass:"more-vert more-vert-enhanced",on:{"click":function($event){return _vm.openActions(file.id, $event)}}},[_c('v-icon',{staticClass:"more-vert-icon"},[_vm._v("mdi-dots-vertical")])],1),(file.id === _vm.activeActionsId)?_c('div',{staticClass:"actions-container"},[_c('div',{staticClass:"actions-list",on:{"click":function($event){return _vm.download(file, $event)}}},[_vm._v(" Download ")]),(_vm.isSupportedThumbnail(file.upload_name) && _vm.isBrandOrCreator)?_c('div',{staticClass:"actions-list",on:{"click":function($event){return _vm.setDefault(file, $event)}}},[_vm._v(" Set Default ")]):_vm._e(),(
              !_vm.checkIsOneHourPast(file.created_at) &&
              (file.user_id === _vm.userId || _vm.isBrandOrCreator)
            )?_c('div',{staticClass:"actions-list",on:{"click":function($event){return _vm.deleteFile(file, $event)}}},[_vm._v(" Delete ")]):_vm._e()]):_vm._e(),_c('center',[_c('v-icon',{staticClass:"non-preview-icon"},[_vm._v("mdi-text-box-outline")])],1),_c('center',{staticClass:"non-preview-content-container"},[_c('center',{staticClass:"non-preview-file-title"},[_c('truncate-hovering',{attrs:{"text":file.title,"length":11}})],1),_c('center',{staticClass:"non-preview-text"},[_vm._v(" "+_vm._s(file.title.split(".").pop())+" ")])],1)],1),_c('div',{staticClass:"date-show"},[_vm._v(" "+_vm._s(_vm.$moment(file.created_at).format("HH:mm, D M Y"))+" ")])])}),1),_c('popup-window',{attrs:{"active":_vm.showPreview,"fullScreen":true,"maxWidth":"fit-content","width":"fit-content"},on:{"popup-close":function($event){return _vm.handlePreview()},"popup-back":function($event){return _vm.handlePreview()}}},[_c('div',[_c('img',{staticStyle:{"max-width":"500px"},attrs:{"src":_vm.previewingFile}})])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }