<template>
  <div class="users-list-container">
    <input
      v-if="searchField"
      v-model="searchValue"
      class="search-field"
      type="text"
      :placeholder="placeholder"
      id="search"
      name="search"
    />
    <table class="table" v-if="listToShow.length">
      <tr v-for="member in listToShow" :key="member.id" class="table-row">
        <td>
          <v-avatar size="50">
            <v-img :src="member.avatar" />
          </v-avatar>
        </td>
        <td class="user-details">
          <div class="user-name-company">
            <div class="user-name">
              {{ member.first_name }} {{ member.last_name }}
            </div>
            <div class="company-name">{{ member.company_name }}</div>
          </div>
          <div class="time-details" v-if="member.read_at">
            <div class="seen-time">
              <small>{{ formatTime(member.read_at) }}</small>
            </div>
            <div class="seen-date">
              <small>{{ formatDate(member.read_at) }}</small>
            </div>
          </div>
        </td>
      </tr>
    </table>
    <div class="no-users-container" v-else>
      {{ !this.searchValue ? "No users seen yet" : "No users found" }}
    </div>
  </div>
</template>

<script>
import moment from "moment"; // Import moment.js

export default {
  name: "SeenList",
  props: {
    searchField: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    membersList: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    listToShow() {
      if (this.searchValue) {
        const regEx = new RegExp(`\\b${this.searchValue}`, "i");
        return this.membersList.filter(
          (member) =>
            regEx.test(`${member.first_name}`) ||
            regEx.test(`${member.last_name}`) ||
            regEx.test(member.company_name)
        );
      }
      return this.membersList;
    },
  },
  data: () => ({
    searchValue: "",
  }),
  methods: {
    formatTime(date) {
      return moment(date).format("hh:mm A");
    },
    formatDate(date) {
      const today = moment().startOf("day");
      const seenDate = moment(date).startOf("day");
      const daysAgo = today.diff(seenDate, "days");

      if (daysAgo === 0) {
        return "Today";
      } else if (daysAgo === 1) {
        return "Yesterday";
      } else {
        return seenDate.format("MM/DD/YYYY");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.users-list-container {
  height: 350px;
  overflow: auto;
  margin: auto;
  width: 100%;
}
.table {
  width: 100%;
  border-spacing: 0 15px;
}
.table-row {
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid $tableBorder;
}
v-avatar {
  flex-shrink: 0;
  margin-right: 15px;
}
.user-details {
  flex-grow: 1;
  padding-left: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.user-name-company {
  text-align: left;
}
.user-name {
  font-weight: bold;
  color: #492a67;
}
.company-name {
  color: #492a67;
  font-size: 0.85rem;
  margin-top: 2px;
}
.time-details {
  text-align: right;
  font-size: 0.9rem;
  color: #555;
}
.seen-time,
.seen-date {
  white-space: nowrap;
}
.search-field {
  margin: 15px 0;
  width: 100%;
  padding: 10px;
  border: 1px solid $primaryDark;
  border-radius: 5px;
  font-size: 0.9rem;
}
input:focus {
  outline: none;
  border-color: $primaryDark;
}
::placeholder {
  color: $primaryDark;
}
.no-users-container {
  text-align: center;
  margin-top: 20px;
  color: $primary;
}
</style>
