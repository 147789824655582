<template>
  <div class="users-list-container">
    <input
      v-if="searchField"
      v-model="searchValue"
      class="search-field"
      type="text"
      :placeholder="placeholder"
      id="search"
      name="search"
    />
    <table class="table" v-if="listToShow.length">
      <tr v-for="member in listToShow" :key="member.id">
        <td class="table-row">
          <tr>
            <td>
              <v-icon v-if="!member.avatar" size="45" color="#473068"
                >mdi-domain</v-icon
              >
              <v-avatar v-else size="50">
                <v-img :src="member.avatar" />
              </v-avatar>
            </td>
            <td class="table-content">
              <tr>
                <td style="font-weight: bold">{{ member.name }}</td>
              </tr>
              <tr>
                <td>
                  <small style="line-height: 9px">
                    {{ member.role && `(${member.role})` }}
                  </small>
                </td>
              </tr>
            </td>
          </tr>
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
export default {
  name: "ChatParticipants",
  props: {
    searchField: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    membersList: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    listToShow() {
      if (this.searchValue) {
        const regEx = new RegExp(`\\b${this.searchValue}`, "i");
        return this.membersList.filter((member) =>
          regEx.test(`${member.name}`)
        );
      }
      return this.membersList;
    },
  },
  data: () => ({
    searchValue: "",
  }),
};
</script>

<style lang="scss" scoped>
.users-list-container {
  height: 350px;
  overflow: auto;
  margin: auto;
}
.table {
  width: 100%;
  margin: 40px 0 40px 0;
  border-bottom: 1px solid $tableBorder;
}
.selected-table-row {
  width: 100%;
  margin: 40px 0 40px 0;
  border: none;
}
.table-row {
  border-top: 1px solid $tableBorder;
  padding-top: 10px;
  text-align: left;
}
.selected-table-row {
  border: none;
  padding-top: 10px;
  text-align: left;
}
.table-content {
  padding-left: 18px;
  vertical-align: top;
  color: $tableBorder;
}
.search-field {
  margin-top: 15px;
}
input[type="text"],
select {
  height: 40px;
  width: 100%;
  border: none;
  border-bottom: 2.5px solid $primaryDark;
  color: #492a67;
}
input:focus {
  outline: none;
}
::placeholder {
  color: $primaryDark;
  font-weight: 400;
}
</style>
